import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { SharedService } from './services/shared.service';
import { PushNotifyService } from './services/push-notify.service';
import { LangService } from './services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

// freshchat default
function initFreshChat(authData) {
  var interval = setInterval(() => {
    if (!!(window as any).fcWidget) {
      (window as any).fcWidget.init({
        token: 'afb86d8a-da1e-4221-8e52-c193e5350df1',
        host: 'https://wchat.freshchat.com',
        tags: ['cerra_web'],
        config: {
          headerProperty: {
            hideChatButton: false,
          },
        },
      });
      (window as any).fcWidget.user.setProperties({
        firstName: authData?.user_details?.first_name,
        lastName: authData?.user_details?.last_name,
        email: authData?.user_details?.email,
        organization: authData?.organization_details?.slug,
      });
      clearInterval(interval);
    }
  }, 2000);
}

// for bous client
function initFreshChatForBous(authData: any) {
  try {
    var chatElement = document.createElement('script');
    chatElement.setAttribute('src', '//fw-cdn.com/2220685/2889759.js');
    document.body.appendChild(chatElement);
    (window as any).fcWidgetMessengerConfig = {
      config: {
        headerProperty: {
          hideChatButton: true,
        },
      },
    };
    var interval = setInterval(() => {
      if ((window as any).fcWidget) {
        (window as any).fcWidget.user.setProperties({
          firstName: authData?.tokenAuth?.user_details?.first_name,
          lastName: authData?.tokenAuth?.user_details?.last_name,
          email: authData?.tokenAuth?.user_details?.email,
          organization: authData?.tokenAuth?.organization_details?.slug,
        });
        clearInterval(interval);
      }
    }, 2000);
  } catch (error) {}
}

function defaultItFreshChart(i, t, authData) {
  var e;
  i.getElementById(t)
    ? initFreshChat(authData)
    : (((e = i.createElement('script')).id = t),
      (e.async = !0),
      (e.src = 'https://wchat.freshchat.com/js/widget.js'),
      (e.onload = initFreshChat(authData)),
      i.head.appendChild(e));
}

function initialize(i, t, app, authData) {
  if (app === 'bous' || app === 'bous-csl') {
    initFreshChatForBous(authData);
  } else {
    defaultItFreshChart(i, t, authData);
  }
}

function initiateCall(app, authData) {
  initialize(document, 'Freshchat-js-sdk', app, authData);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  app = environment.settings.app;
  applyBgColorFromDjango = environment.settings.applyBgColorFromDjango;
  isPushNotificationSupport = environment.settings.isPushNotificationSupport;
  freshchat = environment.settings.freshchat;

  constructor(
    private push: PushNotifyService,
    public shared: SharedService,
    public lang: LangService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    document.body.setAttribute('id', this.app);

    if (this.isPushNotificationSupport && this.shared.isLogin()) {
      this.push.subscribeToNotifications();
      this.push.showMessages();
    }

    if (this.lang.isRTL) {
      document.body.setAttribute('dir', 'rtl');
      const htmlElement = document.getElementsByTagName('html')[0];
      htmlElement.setAttribute('dir', 'rtl');
    }

    if (this.freshchat.isShow) {
      initiateCall(this.app, '');
      if (!!(window as any).fcWidget) {
        if (this.freshchat.pages === 'all') {
          (window as any).fcWidget.show();
        } else if (this.freshchat.pages === 'support') {
          (window as any).fcWidget.show();
        } else {
          (window as any).fcWidget.hide();
        }
      }
    }

    // closing freshchat on clicked outside of freshchat
    this.renderer.listen('document', 'click', (event: MouseEvent) => {
      const launcher =
        this.elementRef.nativeElement.querySelector('.hotline-launcher');
      if (launcher == null) {
        (window as any).fcWidget.close();
      }
    });
  }
}
